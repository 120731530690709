import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';
import LessonContent from './LessonContent';
import Banner from './Banner';
import ChapterNavigation from './ChapterNavigation';
import Timer from './Timer';

const Training = ({ junior, course, chapters, leftIconUrl, rightIconUrl, parcour, current_user }) => {
    const [currentLesson, setCurrentLesson] = useState(chapters[0].lessons[0]);
    const [lessonProgresses, setLessonProgresses] = useState({});

    const fetchLessonProgress = async (lessonId) => {
        const response = await fetch(`/juniors/${junior.current_junior}/lesson_progresses/find_or_create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({ user_id: current_user, lesson_id: lessonId })
        });
        const data = await response.json();
        return data;
    };

    const initializeLessonProgresses = async () => {
        const progressData = {};
        for (const chapter of chapters) {
            for (const lesson of chapter.lessons) {
                const data = await fetchLessonProgress(lesson.id);
                progressData[lesson.id] = data.progress;
            }
        }
        setLessonProgresses(progressData);
    };

    const verifyAndUpdateAllProgresses = async () => {
        const progressPromises = chapters.flatMap(chapter =>
            chapter.lessons.map(lesson => fetchLessonProgress(lesson.id))
        );

        const progressDataArray = await Promise.all(progressPromises);

        const progressData = {};
        progressDataArray.forEach(data => {
            progressData[data.lesson_id] = data.progress;
        });

        setLessonProgresses(progressData);
    };

    useEffect(() => {
        initializeLessonProgresses();
    }, [chapters]);

    const handleLessonClick = async (lesson) => {
        setCurrentLesson(lesson);
        verifyAndUpdateAllProgresses(); // Effectuer la vérification en arrière-plan
    };

    const handleUpdateProgress = (lessonId, progress) => {
        setLessonProgresses(prev => ({ ...prev, [lessonId]: progress }));
    };

    return (
        <div className="training">
            <Banner course={course} parcour={parcour} junior={junior} />
            <ProgressBar
                chapters={chapters}
                currentLesson={currentLesson}
                lessonProgresses={lessonProgresses}
                onLessonClick={handleLessonClick}
                leftIconUrl={leftIconUrl}
                rightIconUrl={rightIconUrl}
            />
            <div className="content">
                <div className="lesson-content">
                    <h1>{currentLesson.title}</h1>
                    <h3>{currentLesson.content}</h3>
                    <h4>{currentLesson.time} minutes</h4>
                    <div className='lesson-content-container'>
                        <LessonContent lessonElements={currentLesson.lesson_elements} junior={junior} current_user={current_user} />
                    </div>
                </div>
                <ChapterNavigation
                    chapters={chapters}
                    currentLesson={currentLesson}
                    onLessonClick={handleLessonClick}
                    lessonProgresses={lessonProgresses}
                />
            </div>
            <Timer
                currentLessonId={currentLesson.id}
                junior={junior}
                current_user={current_user}
                onUpdateProgress={handleUpdateProgress}
            />
        </div>
    );
};

Training.propTypes = {
    junior: PropTypes.shape({
        junior: PropTypes.number.isRequired,
        junior_nom: PropTypes.string.isRequired,
        current_junior: PropTypes.number.isRequired,
    }).isRequired,
    course: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        creator_logo: PropTypes.string.isRequired,
        last_updated_at: PropTypes.string.isRequired,
        difficult: PropTypes.string.isRequired,
        time: PropTypes.string.isRequired,
        is_a_parcour: PropTypes.bool.isRequired,
    }).isRequired,
    chapters: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            lessons: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    position: PropTypes.number.isRequired,
                    title: PropTypes.string.isRequired,
                    time: PropTypes.string,
                    content: PropTypes.string,
                    lesson_elements: PropTypes.arrayOf(
                        PropTypes.shape({
                            id: PropTypes.number.isRequired,
                            element_type: PropTypes.string.isRequired,
                            content: PropTypes.string,
                            video: PropTypes.string,
                            picture: PropTypes.string
                        })
                    ).isRequired,
                })
            ),
        })
    ).isRequired,
    leftIconUrl: PropTypes.string.isRequired,
    rightIconUrl: PropTypes.string.isRequired,
    parcour: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
    }),
    current_user: PropTypes.number.isRequired,
};

export default Training;
